<template>
  <b-modal
    id="unify-patients-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
    @show="onShow"
  >
    <div class="modal-header">
      <div class="modal-title">Unificar paciente</div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <b-row class="mb-3">
        <b-col cols="12" class="phrase">
            Selecione abaixo qual paciente você deseja manter (os demais serão excluídos) e clique em concluir:
        </b-col>
        <b-col cols="12">
            <b-table-simple class="table mb-1">
                <thead>
                    <tr>
                        <td class="td-head">Paciente</td>
                        <td class="td-head">Nº do prontuário</td>
                        <td class="td-head">Celular</td>
                        <td class="td-head">Data de nascimento</td>
                        <td class="td-head"></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="patient in patients" :key="patient.id">
                        <td>
                            <Avatar
                              size="40"
                              :src="patient?.picture"
                              class="mr-3"
                            />
                            {{patient.name?.length > 24 ? patient.name.slice(0, 24) +'...' : patient.name }}
                            <ExternalLink class="external-icon" @click="goPatient(patient)" />
                        </td>
                        <td>{{ patient.medical_record_number || '-' }}</td>
                        <td>{{ maskedPhone(patient.cellphone) }}</td>
                        <td>
                            <span v-if="moment(patient.birthday).isValid()">
                                {{
                                    moment(patient.birthday).format('DD/MM/YYYY')
                                }}
                                ({{
                                    moment().diff(
                                    moment(patient.birthday),
                                    'years'
                                    )
                                }} anos)
                            </span>
                            <div v-else>-</div>
                        </td>
                        <td class="d-flex">
                            <b-form-radio  
                              class="red"
                              @click.native="selectOficialPatient(patient)"
                              :checked="(oficialPatient?.id === patient.id)"
                              :state="validated && !oficialPatients ? false : null"
                            />
                            <Delete 
                                v-b-tooltip.hover title="Remover paciente"
                                class="pointer"
                                @click="removePatient(patient)"
                            />
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
            <div v-if="validated" class="custom-invalid-feedback text-right mt-0">
                <span v-if="patients.length <= 2 && deletePatient"> Para remover um paciente é necessário ter mais de dois pacientes </span>
                <span v-else-if="!oficialPatient"> Selecione pela caixa de seleção qual paciente dejesa manter </span>
            </div>
        </b-col>

        <b-col cols="12" class="mt-3 d-flex justify-content-between">      
          <b-button
            variant="outline-danger"
            @click="closeModal"
          >
            Cancelar
          </b-button>

          <b-button
            variant="primary"
            @click="unifyPatients"
          >
            Concluir
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'UnifyPatientsModal',
  components: {
    Avatar: () => import('@/components/General/Avatar.vue'),
    Close: () => import('@/assets/icons/close.svg'),
    Delete: () => import('@/assets/icons/delete.svg'),
    ExternalLink: () => import('@/assets/icons/external-link.svg')
  },
  props: {
    removeSelectedPatient : Function,
    patients: Array
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      oficialPatient: null,
      validated: false,
      deletePatient: false
    }
  },
  computed: {
    all: {
      get() {
        return !this.form.end_date && !this.form.start_date
      },
      set(newValue) {
        if(newValue){
          this.form.end_date = null
          this.form.start_date = null
        }
      }
    }
  },
  methods: {
    onShow(){
      this.oficialPatient = null
      this.validated = false
      this.deletePatient = false
    },
    maskedPhone(cellphone){
      if(!cellphone){
       return '-' 
      }
      cellphone = cellphone.replaceAll(/\D/g, '')

      if(cellphone.length >= 10){
        return cellphone.replace(/^(\d\d)(\d{4,5})(\d{4}).*/,"($1) $2-$3")
      }
      if(cellphone.length >= 5){
        return cellphone.replace(/^(\d{4,5})(\d{4}).*/,"$1-$2")
      }
      return cellphone
    },
    closeModal() {
      this.$bvModal.hide('unify-patients-modal')
    },
    removePatient(patient){
      this.validated = false
      this.deletePatient = false
      if(this.patients?.length > 2) this.removeSelectedPatient(patient) 
      else{
        this.validated = true
        this.deletePatient = true
      }
    },
    selectOficialPatient(patient){
      this.validated = false
      this.oficialPatient = patient
    },
    unifyPatients() {
      this.deletePatient = false
      this.validated = false
      if (!this.oficialPatient){
        this.validated = true
        return
      }
      const isLoading = this.$loading.show()
      const patientIds = []
      this.patients.forEach(patient => {
          if(patient !== this.oficialPatient) patientIds.push(patient.id)
      })
      this.api.unifyPatients({
          oficial_patient_id: this.oficialPatient.id,
          patient_ids: patientIds,
          clinic_id: this.clinic.id
      })
      .catch((error) => {
        this.$toast.error(error.message)
      })
      .finally(() => {
        this.closeModal()
        this.$emit('unify-patients')
        isLoading.hide()
      })
    },
    goPatient(patient) {
      const route = this.$router.resolve(`/pacientes/${patient.id}`)
      window.open(route.href, '_blank');
    },
  }
}
</script>

<style lang="scss">
#unify-patients-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
  }

  .title-styles {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0a184c;
    margin: 0;
  }

  .checkbox {
      color: gray;
      font-size: 15px;
      text-align: right;
  }

  .loading-label{
    font-weight: 700;
    font-size: 24px;
    line-height: 132%;
    color: var(--dark-blue);
  }

  .message{
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--type-active);
  }

  .table {
      margin: 20px 0;
      background: #FFFFFF;
      border: 1px solid var(--neutral-200);
      border-radius: 80%;

      tr, td {
        padding: 10px 6px;
        font-size: 0.8rem;
        vertical-align: middle;
        color: var(--type-active);
      }
      .td-head {
        font-weight: 600;
        font-size: 14px;
        color: var(--type-active);
        background-color: var(--neutral-100);
      }
    }

    .pointer {
      cursor: pointer;
    }

    .icon {
      width: 24px;
      height: 24px;
      margin-bottom: 5px;
      cursor: pointer;
    }

    .external-icon {
      width: 16px;
      height: 16px;
      stroke: var(--blue-500);
      margin-bottom: 5px;
      margin-left: 3px;
      cursor: pointer;
    }

    .phrase{
      font-weight: 400;
      font-size: 14px;
      color: var(--type-active);
    }
}
</style>